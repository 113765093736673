import { LonaWebComponent, template } from "../../component";
import { Column } from "../../component-builtin";
import { component } from "../../component-decorators";
import { css } from "../../component-styles";
import { $qs } from "../../dom-selectors";
import { Rational } from "@lona/rational";
import { Typography } from "../../ui/typography";
import { EditableText } from "../editable-text";
import { DomUtils } from "../../dom";

@component({
  name: "std-list-cell-layout",
})
export class ListCellLayout<
  $Content extends HTMLElement = HTMLElement
> extends LonaWebComponent {
  static styles = {
    $padding: css`
      .list-cell-root {
        padding: var(--list-cell-padding, 6px 8px);
        border-radius: 8px;
      }
      :host([size="wide"]) .list-cell-root {
        padding: var(--list-cell-padding, 10px 8px);
        border-radius: 10px;
      }
      :host([size="large"]) .list-cell-root {
        padding: var(--list-cell-padding, 10px 20px);
        border-radius: 10px;
      }
      :host([size="small"]) .list-cell-root {
        padding: 4px 6px;
      }
    `,
    $reorderDecoration: css`
      :host([is-being-dragged]) .list-cell-root {
        background-color: var(--hover-color);
      }
      :host([is-being-dragged-over]) .list-cell-root::after {
        background: var(--blue);
        content: "";
        position: absolute;
        left: 0px;
        height: 2px;
        border-radius: 2px;
        width: 100%;
      }
      :host([is-being-dragged-over="top"]) .list-cell-root::after {
        top: -1px;
      }
      :host([is-being-dragged-over="bottom"]) .list-cell-root::after {
        bottom: -1px;
      }
    `,
  };

  $content(): $Content {
    return $qs<$Content>(`[slot="content"]`, this);
  }

  get position(): Option<Rational> {
    const raw = this.getAttribute("position");
    if (!raw) return null;
    return Rational.fromString(raw);
  }

  set position(r: Option<Rational>) {
    this.setAttribute("position", r ? Rational.toString(r) : "");
  }

  static toggleEditable($cell: ListCellLayout<EditableText>, force: boolean) {
    $cell.toggleAttribute("editing", force);
    if (force) {
      $cell.$content().enableEditing(false);
    } else {
      $cell.$content().disableEditing();
    }
  }

  static makeWith<$Content extends HTMLElement = HTMLElement>(
    options: Optional<{
      $icon: HTMLElement;
      $content: $Content;
      $accessory: HTMLElement;
      position: Rational;
    }>,
    size: "small" | "default" | "large" | "wide" = "default"
  ): ListCellLayout<$Content> {
    const $e = ListCellLayout.make() as ListCellLayout<$Content>;
    options.$icon && (options.$icon.slot = "icon");
    options.$icon && $e.appendChild(options.$icon);
    options.$content && (options.$content.slot = "content");
    options.$content && $e.appendChild(options.$content);
    options.$accessory && (options.$accessory.slot = "accessory");
    options.$accessory && $e.appendChild(options.$accessory);
    options.position &&
      $e.setAttribute("position", Rational.toString(options.position));
    $e.setAttribute("size", size);
    return $e;
  }

  static makeText(config: {
    title: string;
    caption?: Option<string>;
    position?: Option<Rational>;
    $accessory?: Option<HTMLElement>;
  }): ListCellLayout<HTMLElement> {
    const $content = Column.make();
    $content.style.alignItems = "start";
    $content.appendChild(Typography.p(config.title));
    $content.style.marginTop = "3px";
    $content.style.marginBottom = "3px";
    if (config.caption) {
      const $caption = Typography.caption(config.caption);
      DomUtils.assignStyles($caption, {
        color: "var(--tertiary-text-color)",
        "--margin-top": "8px",
      });
      $caption.classList.add("small");
      $content.appendChild($caption);
    }
    return ListCellLayout.makeWith({
      $content,
      $accessory: config.$accessory,
      position: config.position,
    });
  }

  static $styles = [
    ListCellLayout.styles.$padding,
    ListCellLayout.styles.$reorderDecoration,
    css`
      :host {
        --p-hover-color: var(
          --list-cell-background-color,
          var(--list-cell-layout-hover-color, var(--hover-color))
        );
        --p-selected-bg-color: var(
          --list-cell-background-color,
          var(--list-cell-selected-background-color, var(--selected-color))
        );
        --p-bg-color: var(--list-cell-background-color, none);
      }

      #root {
        position: relative;
        background-color: var(--p-bg-color);
      }

      #root:hover {
        background: var(--p-hover-color);
      }

      :host([context-menu-open]) #root,
      :host([selected]) #root {
        background-color: var(--p-selected-bg-color);
      }

      slot[name="icon"]::slotted(*) {
        flex-grow: 0;
        margin-right: 8px;
      }

      slot[name="content"]::slotted(*) {
        flex-grow: 1;
        flex-shrink: 1;
      }

      slot[name="accessory"]::slotted(*) {
        flex-shrink: 0;
      }
    `,
    css`
      :host([editing]) #root {
        position: relative;
        background-color: transparent;
        cursor: text;
        color: var(--primary-text-color);
        background: transparent;
      }
      :host([editing]) #root:before {
        content: "";
        position: absolute;
        inset: 0px;
        border: 2px solid var(--blue);
        border-radius: 8px;
      }
      :host([size="wide"][editing]) #root:before {
        border-radius: 10px;
      }
    `,
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <std-row id=root class=list-cell-root>
      <slot name=icon></slot>
      <slot name=content></slot>
      <slot name=accessory></slot>
    </std-row>
  `;
}
