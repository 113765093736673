import { LonaWebComponent, template } from "../component";
import { component } from "../component-decorators";
import { css } from "../component-styles";
import { Constants } from "../constants";
import { DomUtils } from "../dom";
import { DomEventUtils } from "../dom-event-utils";
import { Typography } from "../ui/typography";
import { Placeholder } from "./placeholder";

/**
 * There's no simple way to create an editable web text component unfortunately.
 *
 * Couple of options considered:
 *   1) The element itself can be toggled contenteditable
 *
 *      ie. <std-editable-text contenteditable>Hello</...>
 *
 *          const template = `<slot></slot>`
 *
 *      This would seem like the most canonical way of wiring up such a
 *      component. Unfortunately, text selection and deletion act on text
 *      "nodes". In other words, a single backspace character will delete the
 *      entire string of characters
 *
 *   2) The element wraps a contenteditable component
 *
 *      This is what's used below.
 *
 *      Unfortunately, we need to consider that when the node is cloned, the
 *      state of the internal shadowRoot is not replicated. This means that we
 *      will have to also encode the text content of the element as either an
 *      attribute or the ~innerHTML~. Note: it's not possible to set the
 *      innerHTML here since it will cause the component to blur.
 *
 *      During rehydration (onConnectedCallback), we will have to rebind as per
 *      what's in the serialized DOM element.
 *
 *      This is suboptimal for the design pattern we're using since it means
 *      that there are technically multiple sources of truth for the data of a
 *      given component. (EdtiableText::bind vs setAttribute("text", "hello")).
 *
 *      The latter seems more "correct" but requires a lot more plumbing.
 *
 *      Will need to experiment with the 2 to see how they fare.
 *
 *   3) We use a virtual DOM to manage the contenteditable state
 *
 *      This unfortunately breaks encapsulation pretty hard - we need a
 *      mutation observer to handle cases such as Apple's emoji picker,
 *      copy-paste, etc. Maybe it's possible to create some sort of a manager
 *      to decorate certain divs, but overall I'm not sure if it's worth the
 *      hassle.
 *
 */
@component({
  name: "std-editable-text",
})
export class EditableText extends LonaWebComponent {
  controller: EditableText.Controller = new EditableText.Controller(
    this.$("root")
  );

  static get observedAttributes() {
    return ["text"];
  }

  get text(): Option<string> {
    return this.controller.text;
  }

  static makeWith(
    text: Option<string>,
    onTextChange: (t: Option<string>) => void,
    options?: Optional<{
      placeholder: string;
      withOutline: boolean;
    }>
  ): EditableText {
    const $e = EditableText.make();
    $e.bind(text, options?.placeholder, options?.withOutline ?? false);
    $e.controller.onTextChange = onTextChange;
    return $e;
  }

  toggleEditable(editable: boolean, focus: boolean = true) {
    if (editable) {
      this.controller.enableEditing(focus);
    } else {
      this.controller.disableEditing();
    }
    this.toggleAttribute("disabled", !editable);
  }

  toggleEditing(focus: boolean = true) {
    this.controller.toggleEditing(focus);
  }

  enableEditing(focus: boolean = true) {
    this.controller.enableEditing(focus);
  }

  disableEditing() {
    this.controller.disableEditing();
  }

  bind(
    text: Option<string>,
    placeholder: Option<string> = null,
    withOutline: boolean = false
  ) {
    this.$("root").setAttribute("placeholder", placeholder ?? "");
    this.setAttribute("text", text ?? "");
    this.controller.text = text ?? "";
    this.toggleAttribute("with-outline", withOutline);
  }

  bindText(text: Option<string>) {
    this.setAttribute("text", text ?? "");
    this.controller.text = text ?? "";
  }

  attributeChangedCallback(
    name: string,
    oldValue: Option<string>,
    newValue: Option<string>
  ) {
    if (this.isConnected) return;
    this.controller.text = newValue;
  }

  static $styles = [
    Placeholder.$style,
    css`
      :host {
        position: relative;
        --font-size: var(--p-size);
        --line-height-ratio: 1.1;
      }

      :host([disabled]) {
        pointer-events: none;
        cursor: default;
      }

      :host([disabled]) [placeholder]::before {
        content: "";
      }

      #root {
        width: 100%;
        padding-inline: var(--padding-inline);
        padding-block: var(--padding-block);
        --placeholder-left: var(--padding-inline);
        --placeholder-top: var(--padding-block);
        cursor: text;
      }

      #root:focus,
      :host,
      :host:focus {
        outline: none;
      }

      slot {
        user-select: all;
      }

      :host([with-outline]:hover)::after {
        content: "";
        position: absolute;
        inset: -8px;
        border: 1px solid var(--divider-color);
        pointer-events: none;
        border-radius: 4px;
      }
    `,
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <std-flex>
      <std-text id=root hide-placeholder-on-focus></std-text>
    </std-flex>
  `;
}

export namespace EditableText {
  export class Controller {
    private $e: HTMLElement;

    onTextChange: (t: Option<string>) => void = Constants.EMPTY_FUNCTION;
    onEnterKey: EmptyFunction = Constants.EMPTY_FUNCTION;
    onEditingStarted: EmptyFunction = Constants.EMPTY_FUNCTION;
    onEditingFinished: EmptyFunction = Constants.EMPTY_FUNCTION;

    observer = new MutationObserver(() => {
      this.onTextChange(this.text);
    });

    get text(): Option<string> {
      return this.$e.textContent != "" ? this.$e.textContent : null;
    }

    set text(text: Option<string>) {
      this.observer.disconnect();
      this.$e.textContent = text ?? "";
      this.observer.observe(this.$e, {
        characterData: true,
        childList: true,
        subtree: true,
      });
    }

    constructor($e: HTMLElement) {
      this.$e = $e;

      this.$e.addEventListener("keydown", (e) => {
        if (e.key == "Enter") {
          this.onEnterKey();
          e.preventDefault();
        }
        e.stopPropagation();
      });
      this.$e.addEventListener("keyup", DomEventUtils.STOP_PROPAGATION);
      this.$e.onblur = () => this.onEditingFinished();
      this.$e.onfocus = () => this.onEditingStarted();
    }

    toggleEditing(focus: boolean = true) {
      if (this.$e.hasAttribute("contenteditable")) {
        this.disableEditing();
      } else {
        this.enableEditing(focus);
      }
    }

    enableEditing(focus: boolean = true) {
      this.$e.toggleAttribute("contenteditable", true);
      DomUtils.toggleInteractable(this.$e, true);
      if (focus) {
        this.$e.focus();
        // DomUtils.setEndOfContenteditable(this.$("root"));
      }
      this.observer.observe(this.$e, {
        characterData: true,
        childList: true,
        subtree: true,
      });
      this.$e.onpointerdown = DomEventUtils.STOP_PROPAGATION;
      this.$e.onpointerup = DomEventUtils.STOP_PROPAGATION;
      this.$e.ondblclick = DomEventUtils.STOP_PROPAGATION;
    }

    disableEditing() {
      this.$e.blur();
      this.$e.toggleAttribute("contenteditable", false);
      DomUtils.toggleInteractable(this.$e, false);
      this.$e.onpointerdown = null;
      this.$e.onpointerup = null;
      this.$e.ondblclick = null;
    }
  }
}
