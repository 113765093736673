import { css } from "../../component-styles";

export namespace Link {
  export const style = /* css */ `
    a {
      text-decoration: none;
      color: var(--primary-text-color);
    }
    a:hover {
      text-decoration: underline;
    }
    a:visited {
      color: var(--primary-text-color);
    }
  `;

  export const $style = css(style);

  export const make = () => document.createElement("link");
}
