import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { css } from "@lona/component-styles";

@component({
  name: "lona-marketing-card",
})
export class MarketingCard extends LonaWebComponent {
  static $styles: HTMLTemplateElement[] = [
    css`
      :host {
        background-color: #f1f1f1;
        border-radius: 24px;
        overflow: hidden;
      }

      #root {
        height: 100%;
      }
      #header {
        padding: 16px;
        align-items: start;
      }
      .number {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        border: 2px solid black;
        flex-shrink: 0;
      }
      .center-container {
        display: grid;
        place-items: center;
      }

      slot[name="content"]::slotted(*) {
        flex-grow: 1;
      }
    `,
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <std-col id=root>
      <std-row id=header style=align-items:start;>
        <div class="center-container number">
          <slot name=number></slot>
        </div>
        <std-col style=margin-left:12px;padding-top:0px;align-items:start;>
          <slot name=label></slot>
          <slot name=subtitle></slot>
        </std-col>
      </std-row>
      <div style=flex-grow:1;>
        <slot name=content></slot>
      </div>
    </std-col>
  `;
}
