import { assert, nonnull } from "./assert";

export type RationalLike = {
  n: number;
  d: number;
};

export class Rational {
  constructor(public readonly n: number, public readonly d: number) {}
}

export namespace Rational {
  export function make(r: RationalLike): Rational {
    return new Rational(r.n, r.d);
  }

  export function first(): Rational {
    return {
      n: 1,
      d: 1,
    };
  }

  export function nth(n: number): Rational {
    if (n > 0) {
      return {
        n: 1,
        d: n,
      };
    } else if (n == 0) {
      return {
        n: 2,
        d: 2,
      };
    } else {
      return {
        n: 1 - n + 1,
        d: 1,
      };
    }
  }

  export function next(curr: Rational): Rational {
    return {
      n: curr.n,
      d: curr.d + 1,
    };
  }

  export function midpoint(upper: Option<Rational>, lower: Option<Rational>) {
    if (!upper) {
      lower = nonnull(lower);
      return { n: lower.n + 1, d: lower.d };
    }
    if (!lower) {
      upper = nonnull(upper);
      return { n: upper.n, d: upper.d + 1 };
    }
    return { n: upper.n + lower.n, d: upper.d + lower.d };
  }

  export function toString(r: Rational): string {
    return `${r.n}/${r.d}`;
  }

  export function fromString(s: string): Rational {
    const [n, d] = s.split("/");
    return {
      n: parseInt(n),
      d: parseInt(d),
    };
  }

  export function compare(a: Rational, b: Rational): number {
    assert(a.d < Number.MAX_SAFE_INTEGER);
    assert(a.n < Number.MAX_SAFE_INTEGER);
    assert(b.n < Number.MAX_SAFE_INTEGER);
    assert(b.d < Number.MAX_SAFE_INTEGER);
    const positionA = a.d * b.n;
    const positionB = b.d * a.n;
    if (positionA > positionB) return 1;
    else if (positionA < positionB) return -1;
    return 0;
  }
}
