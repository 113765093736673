import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { css } from "@lona/component-styles";
import { Link } from "@lona/components/link/link";

@component({
  name: "lona-marketing-header",
})
export class MarketingHeader extends LonaWebComponent {
  static $styles: HTMLTemplateElement[] = [
    Link.$style,
    css`
      :host {
        position: sticky;
        top: 0;
        width: 100%;
      }

      nav {
        align-self: center;
        width: calc(100vw - 40px);
        max-width: calc(var(--max-width) - 40px);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: auto;
        margin-right: auto;
      }

      #nav-logo {
        height: 32px;
        width: 32px;
        margin-right: 12px;
        border-radius: 6px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.18);
      }
      #nav-button {
        margin-left: 24px;
        padding: 8px 10px;
        font-weight: 300;
        border-radius: 8px;
      }
      .outline-button {
        background-color: transparent;
        color: black;
      }
      .outline-button:hover {
        background-color: rgba(0, 0, 0, 0.06);
      }
      #nav-button,
      nav {
        transition: color 0.3s ease;
      }
    `,
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <nav>
      <a class=row href="/landing" style=align-items:center;>
        <img id=nav-logo src="/@/static/img/logo/logo-64.png" />
        <h1 id=nav-title class=h6 style="font-weight:400;">Lona</h1>
      </a>

      <std-spacer></std-spacer>

      <std-row style=align-items:center;>
        <slot></slot>
      </std-row>
    </nav>
  `;
}
