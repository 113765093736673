import "@lona/components/list/list-cell-layout";

import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { css } from "@lona/component-styles";
import { Link } from "@lona/components/link/link";
import { DomUtils } from "@lona/dom";

@component({
  name: "lona-marketing-footer",
})
export class MarketingFooter extends LonaWebComponent {
  private $cell = DomUtils.hydrate(this.$("cell"));

  static $styles = [
    Link.$style,
    css`
      :host {
        width: 100%;
      }

      #root {
        padding: 12px;
        max-width: calc(var(--max-width) - 40px);
        margin-left: auto;
        margin-right: auto;
      }

      .footer-link {
        position: relative;
        margin-left: 24px;
        color: #444;
      }

      .footer-link:visited {
        color: #444;
      }

      .footer-link:not(:first-of-type)::before {
        content: "";
        position: absolute;
        /* width: 1px; */
        left: -12px;
        top: 0px;
        bottom: 0px;
        border-left: 1px solid black;
      }

      .list-cell-icon {
        height: 32px;
        width: 32px;
        border-radius: 16px;
        background: #888;
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <div id="root">

      <div class="content-body">
        <std-row style="align-items: center; color: #444; flex-wrap: wrap; gap: 12px;">

          <!-- Profile List Cell -->

          <a target="_blank" href="http://jameskao.io">
            <std-list-cell-layout id=cell>
              <div slot=icon class=list-cell-icon></div>
              <std-col slot=content style=margin-top:3px;padding-right:4px;>
                <p style="margin-bottom: 3px;" class=small>Come say hi 😄 - @yuzuquat</p>

                <p class=small><span style="padding-top: 4px; padding-right: 4px;">©</span>2022</p>
              </std-col>
            </std-list-cell-layout>
          </a>

          <std-spacer></std-spacer>
          <div>
            <a class="footer-link" target="_blank" href="/pricing" style="margin-left: 0px;">Pricing</a>
            <a class="footer-link" target="_blank" href="/tos">Terms of Service</a>
            <a class="footer-link" target="_blank" href="/privacy-policy">Privacy Policy</a>
          </div>

        </std-row>
      </div>
    </div>
  `;
}
