import { err } from "./log";

export const assert = (cond: boolean, message?: string) => {
  if (!cond) {
    err()(message);
    throw new Error(message);
  }
};

export const assertEqual = (o1: any, o2: any, message?: string) => {
  if (!o1 == o2) {
    err()(o1, "is not equal to", o2, message);
    throw new Error(`${o1} != ${o2}`);
  }
};

export const nonnull = function <T>(val: Option<T>, message?: string): T {
  // @ts-ignore
  if (
    val === null ||
    val === undefined ||
    (typeof val == "number" && isNaN(val))
  ) {
    message = message ?? `Got null or NaN value: ${val}`;
    err()(message);
    throw new Error(message);
  }
  return val;
};

export const nonnull_noexcept = function <T>(
  val: Option<T>,
  message?: string
): T {
  // @ts-ignore
  if (val == null || (typeof val == "number" && isNaN(val))) {
    message = message ?? `Got null or NaN value: ${val}`;
    console.trace();
    err()(message);
  }
  return val as T;
};
